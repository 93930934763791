export default class toggleSwitch {
    constructor(switchClass) {
        this.activeClass = 'is-active';
        this.element = document.querySelector(switchClass);
        this.buttons = this.element.querySelectorAll('.toggle-switch__button');
        this.labels = this.element.querySelectorAll('.toggle-switch__label');
        this.highlight = this.element.querySelector('.toggle-switch__selector');
        this.activeBtn = this.element.querySelector(`.toggle-switch__button.${this.activeClass}`);
        this.activeLabel = this.element.querySelector(`.toggle-switch__label.${this.activeClass}`);

        this._highlight();
        this._addEvents();
    }

    _highlight() {
        const w = this.activeBtn.offsetWidth;
        const x = this.activeBtn.offsetLeft;

        this.highlight.style.width = `${w}px`;
        this.highlight.style.transform = `translateX(${x}px)`;
    }

    _dispatchEvent() {
        this.element.dispatchEvent(
            new CustomEvent('change', {
                detail: this.activeBtn.dataset.lang,
            })
        );
    }

    _addEvents() {
        [].forEach.call(this.buttons, (btn) => {
            btn.addEventListener('click', e => {
                if (this.activeBtn === e.target) return;

                this.activeBtn.classList.remove(this.activeClass);
                this.activeBtn = e.target;
                this.activeBtn.classList.add(this.activeClass);

                this.activeLabel.classList.remove(this.activeClass);
                this.activeLabel = this.element.querySelector(`[data-label="${e.target.dataset.lang}"]`);
                this.activeLabel.classList.add(this.activeClass);
                this._highlight();
                this._dispatchEvent();
            });
        });
    }
}
